<template>
  <div>
    <div class="justify-center align-center text-center d-flex py-2">
      <v-expansion-panels
        style="max-width: 600px;"
        class="rounded-xl justify-center align-center"
        :class="isPopUp ? '' :'panel-background'"
      >
        <v-expansion-panel
          v-for="(item, index) in timingData"
          :key="index"
        >
          <v-expansion-panel-header
            class="px-1"
          >
            <v-row no-gutters>
              <v-card
                color="transparent"
                flat
              >
                <v-card-title class="my-0 py-0">
                  {{ searchType(item.type) }} {{ (item.type === 4 || item.type === 1) ? $t('round') + (item.round + 1) : '' }}
                </v-card-title>
                <v-card-subtitle class="my-0 py-0 pt-2">
                  <div style="display: flow">
                    {{ formattedDate(item.startFrom) || 'Not set' }}
                    <v-icon
                      color="primary"
                      class="mx-0 px-0"
                    >
                      {{ icons.mdiCircleSmall }}
                    </v-icon>
                    {{ textByDays(daysBeforeEvent(index)) }}
                  </div>
                </v-card-subtitle>
                <v-card-subtitle class="my-0 py-0">
                  {{ $t('startFrom') }}{{ item.time || 'Not set' }}
                </v-card-subtitle>
              </v-card>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              justify="space-around"
              no-gutters
            >
              <v-col
                cols="12"
                md="5"
                sm="5"
              >
                <v-dialog
                  ref="dialog"
                  v-model="dialogDate"
                  width="290px"
                  :retain-focus="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formattedDate(item.startFrom)"
                      :label="$t('Date')"
                      dense
                      readonly
                      v-bind="attrs"
                      hide-details
                      class="justify-center align-center"
                      v-on="on"
                      @click="editingIndex = index"
                    >
                      <template #prepend>
                        <v-avatar
                          size="36"
                          class="v-avatar-light-bg primary--text"
                        >
                          <v-icon
                            size="26"
                            color="primary"
                          >
                            {{ icons.mdiCalendar }}
                          </v-icon>
                        </v-avatar>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    :value="editingIndex !== null ? timingData[editingIndex].date: item.date"
                    scrollable
                    color="primary"
                    no-title
                    :min="editingIndex !== null ? timingData[editingIndex].min : item.min"
                    :max="editingIndex !== null ? timingData[editingIndex].max: item.max"
                    class="rounded-xl"
                    :allowed-dates="allowedDates"
                    @change="updateDate(editingIndex, $event)"
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col
                cols="12"
                md="5"
                sm="5"
              >
                <v-dialog
                  ref="dialogTime"
                  v-model="timeDialog"
                  width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.time"
                      :label="$t('startFrom')"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      hide-details
                      dense
                      class="justify-center align-center"
                      v-on="on"
                      @click="editingIndex = index"
                    >
                      <template #prepend>
                        <v-avatar
                          size="36"
                          class="v-avatar-light-bg primary--text"
                        >
                          <v-icon
                            size="26"
                            color="primary"
                          >
                            {{ icons.mdiClockOutline }}
                          </v-icon>
                        </v-avatar>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeDialog"
                    :value="editingIndex !== null ? timingData[editingIndex].time: item.time"
                    full-width
                    format="24hr"
                    :min="editingIndex !== null ? minTime(editingIndex): minTime(index)"
                    :max="editingIndex !== null ? maxTime(editingIndex): maxTime(index)"
                    :allowed-minutes="allowedMinutes"
                    scrollable
                    class="rounded-xl"
                    @click:hour="handleHourClick"
                    @click:minute="handleMinuteClick"
                  >
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiCalendar, mdiCircleSmall, mdiClockOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'

export default {
  props: ['packageId', 'isPopUp'],
  setup(props, { emit }) {
    const toast = useToast()
    const showToast = message => toast.error(i18n.t(message))

    const editingIndex = ref(null)
    const timeDialog = ref(false)
    const dialogDate = ref(false)

    // Interval for select time
    const allowedMinutes = m => m % 10 === 0

    const formattedDate = dateItem => {
      if (!dateItem) return ''

      return moment(dateItem).format('DD/MM/YY')
    }

    const timingData = ref([])
    const minTime = index => {
      if (timingData.value[index].workingDayStart > timingData.value[index].min) {
        return moment(timingData.value[index].workingDayStart).format('HH:mm')
      }

      return moment(timingData.value[index].min).format('HH:mm')
    }
    const maxTime = index => {
      if (timingData.value[index].workingDayEnd > timingData.value[index].max) {
        return moment(timingData.value[index].max).format('HH:mm')
      }

      return moment(timingData.value[index].workingDayEnd).format('HH:mm')
    }

    const daysBeforeEvent = index => {
      if (index == null) return null
      const item = timingData.value[index]
      if (!item || !item.startFrom) return null // Check if item or item.startFrom is null or undefined

      // Convert the time of event data and the selected date to the start of the day (00:00)
      const eventDate = moment(store.state.eventData.date).startOf('day')
      const itemDate = moment(item.startFrom).startOf('day')

      if (!eventDate.isValid() || !itemDate.isValid()) {
        return null // Check if the converted dates are valid
      }

      const diffInDays = eventDate.diff(itemDate, 'days')

      return diffInDays
    }

    const textByDays = days => {
      if (days === null) return ''

      if (days === 0) {
        return i18n.t('sameDay')
      }
      if (days === 1) {
        return i18n.t('dayBefore')
      }
      if (days === 2) {
        return i18n.t('twoDaysBefore')
      }
      if (days === -1) {
        return i18n.t('dayAfter')
      }
      if (days === -2) {
        return i18n.t('twoDaysAfter')
      }

      if (days < 0) {
        return `${Math.abs(days)} ${i18n.t('daysAfterEvent')}`
      }

      return `${days} ${i18n.t('daysBeforeEvent')}`
    }

    const fetchTiming = (data = {}) => {
      const requestData = props.packageId ? { ...data, packageId: props.packageId } : data

      store
        .dispatch('fetchTiming', { id: store.state.eventData.id, data: requestData })
        .then(res => {
          timingData.value = res.data.map(el => {
            const currentDate = new Date()
            let minDate

            // Check if 'min' exists and if it's past the current date
            if (el.min) {
              const minDateObject = new Date(el.min)
              minDate = minDateObject < currentDate ? currentDate.toISOString() : el.min
            } else {
              minDate = currentDate.toISOString()
            }

            const updatedEl = {
              ...el,
              date: new Date(el.startFrom).toISOString().split('T')[0],
              time: moment(el.startFrom).format('HH:mm'),
              hour: moment(el.startFrom).format('HH'),
              minute: moment(el.startFrom).format('mm'),
              min: minDate,
            }

            return updatedEl
          })
          emit('loadingComplete')
        })
        .catch(e => {
          showToast(i18n.t('errorManageEvents'))
        })
    }

    fetchTiming()

    const updateDate = (index, dateToUpdate) => {
      const now = moment()
      const selectedDate = moment(dateToUpdate)

      let timeIndex
      if (selectedDate.isSame(now, 'day')) {
        // If the selected date is today, set the time to the current hour or keep the existing time if it's later
        const existingTime = moment(timingData.value[index].time, 'HH:mm')
        if (existingTime.isValid() && existingTime.isAfter(now)) {
          timeIndex = existingTime.format('HH:mm')
        } else {
          // Round up to the nearest 10 minutes
          const roundedMinutes = Math.ceil(now.minute() / 10) * 10
          timeIndex = now.set('minute', roundedMinutes).format('HH:mm')
        }
      } else {
        // Default to 09:00 if no time is set
        timeIndex = timingData.value[index].time || '09:00'
      }

      const combinedDateTime = selectedDate.format(`YYYY-MM-DDT${timeIndex}:00`)
      timingData.value[index].startFrom = moment(combinedDateTime).toISOString()

      fetchTiming({ shouldSave: true, timings: timingData.value })
      dialogDate.value = false
    }

    const updateTime = (index, timeUpdate) => {
      const dateStr = timingData.value[index].startFrom || new Date().toISOString().split('T')[0] // default to today if no date is set
      const dateObj = new Date(dateStr)
      const combinedDateTime = `${dateObj.toISOString().split('T')[0]}T${timeUpdate}:00`
      timingData.value[index].time = timeUpdate
      timingData.value[index].startFrom = new Date(combinedDateTime).toISOString()

      fetchTiming({ shouldSave: true, timings: timingData.value })

      timeDialog.value = false
    }

    const handleHourClick = hour => {
      const paddedHour = hour < 10 ? `0${hour}` : hour

      timingData.value[editingIndex.value].hour = paddedHour
    }

    const handleMinuteClick = minute => {
      const paddedMinute = minute < 10 ? `0${minute}` : minute

      if (editingIndex.value !== null) {
        const currentTime = timingData.value[editingIndex.value].hour
        const newTime = `${currentTime}:${paddedMinute}`

        updateTime(editingIndex.value, newTime)
      } else {
        timeDialog.value = false
      }
    }

    const searchType = type => {
      if (type === null) return ''

      if (type === 1) {
        return i18n.t('invitationMessage')
      }
      if (type === 2) {
        return i18n.t('DirectionMessage')
      }
      if (type === 3) {
        return i18n.t('ThanksMsg')
      }
      if (type === 4) {
        return i18n.t('call')
      }
    }

    // not allow select Saturday
    const allowedDates = dateValue => {
      const day = new Date(dateValue).getDay()

      // Return true for days other than Saturday (6)
      return day !== 6
    }

    return {
      timeDialog,
      minTime,
      maxTime,
      allowedMinutes,
      dialogDate,
      formattedDate,
      timingData,
      updateDate,
      updateTime,
      editingIndex,
      daysBeforeEvent,
      textByDays,
      fetchTiming,
      searchType,
      handleMinuteClick,
      handleHourClick,
      allowedDates,
      moment,

      icons: {
        mdiClockOutline,
        mdiCalendar,
        mdiCircleSmall,
      },
    }
  },
}
</script>

<style scoped>
.panel-background {
  border-top: solid 4px var(--v-primary-base);
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 5px !important;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
  box-shadow: unset !important;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.v-application.theme--light .v-expansion-panel::before {
  box-shadow: unset !important;
}
</style>
